import * as THREE from 'three'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'
//import { GUI } from 'three/examples/jsm/libs/stats.module.js'

import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer.js'
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass.js'
import {BokehPass} from 'three/examples/jsm/postprocessing/BokehPass.js'

console.log(THREE.REVISION)

// Sizes
	
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}
	
var windowHalfX = sizes.width / 2;
var windowHalfY = sizes.height / 2;

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
var clock = new THREE.Clock();
var mouseX = 0,
	mouseY = 0;

// Objects
var N_OBJECTS = 750;
var meshes = [];
var noodlemesh = new THREE.BufferGeometry();
var group = new THREE.Object3D();
var dd = 160;
var mesh;

const meshloader = new GLTFLoader();
meshloader.load('./3D/a.gltf', function(gltf) {
	noodlemesh = gltf.scene.children[0].geometry;
    noodlemesh.scale(.015,.015,.015)
	makeitrain();
});

const particleGeo = new THREE.BufferGeometry
const particleCount = 2500
const posArray = new Float32Array(particleCount * 3)
const texLoader = new THREE.TextureLoader()
const partMat = texLoader.load('./img/circle.png')
const materialwhite = new THREE.PointsMaterial({
    color: 0x444499,
    size:0.15,
    map: partMat,
    transparent: true,
    blending: THREE.AdditiveBlending
})

for(let i=0;i<particleCount*3;i++){
    posArray[i] = (Math.random()-0.5)*100
}

particleGeo.setAttribute('position', new THREE.BufferAttribute(posArray,3))
const particleMesh = new THREE.Points(particleGeo,materialwhite)
scene.add(particleMesh)
	
// Materials

const cubeLoader = new THREE.CubeTextureLoader();
cubeLoader.setPath('./img/')
const reflectionCube = cubeLoader.load( [
	'px.jpg', 'nx.jpg',
	'py.jpg', 'ny.jpg',
	'pz.jpg', 'nz.jpg'
] );

const material = new THREE.MeshPhysicalMaterial({
	envMap: reflectionCube,
    roughness:0.1,
    reflectivity: 1,
    color: 0x1a1a1a
});

const matLight = new THREE.MeshBasicMaterial({
    color: 0xffffff
})

const matGlass = new THREE.MeshPhysicalMaterial({
    transmission:1,
    roughness: .6,
    thickness: .1,
    clearcoat: 1,
    clearcoatRoughness:0.0,
    envMap: reflectionCube,
    envMapIntensity: 1,
})

// Lights

var light1 = new THREE.PointLight(0x1734FE, 1, 200);
light1.position.set(40, 0, 0);
scene.add(light1);

var light2 = new THREE.PointLight(0x09A0B1, 1, 200);
light2.position.set(-40, 0, 0);
scene.add(light2);

var light3 = new THREE.PointLight(0x18EC85, 1, 200);
light3.position.set(0, 0, 40);
scene.add(light3);

var ambientLight = new THREE.AmbientLight(0xffffff,1)
//scene.add(ambientLight)


// Base camera
const camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.01,200000);
camera.position.z = 50;
//let distance


//Document handlers

document.addEventListener('mousemove', onDocumentMouseMove, false);
document.addEventListener('touchmove', onTouchMove,{passive: false});

window.addEventListener('resize', () => {
// Update sizes
sizes.width = window.innerWidth
sizes.height = window.innerHeight

// Update camera
camera.aspect = sizes.width / sizes.height
camera.updateProjectionMatrix()

// Update renderer
renderer.setSize(sizes.width, sizes.height)
composer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(window.devicePixelRatio)
//renderer.outputEncoding = THREE.sRGBEncoding
})


// Renderer

const renderer = new THREE.WebGLRenderer({
canvas: canvas,
antialias: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

// Composer
let composer
//const postprocessing={}

function makeitrain() {

    for (var i = 0; i < N_OBJECTS; i++) {
        //mats[Math.floor(Math.random()*mats.length)]
        let pickedMat

        var r = Math.random()
        if(r<=0.015){
            pickedMat = matLight
        }else{
            pickedMat = material
        }

        mesh = new THREE.Mesh(noodlemesh, pickedMat);

        mesh.position.x = THREE.MathUtils.randFloatSpread(dd);
        mesh.position.y = THREE.MathUtils.randFloatSpread(dd);
        mesh.position.z = THREE.MathUtils.randFloatSpread(dd);

        mesh.rotation.x = Math.random() * 360 * (Math.PI / 180);
        mesh.rotation.y = Math.random() * 360 * (Math.PI / 180);
        mesh.scale.x = mesh.scale.y = mesh.scale.z = Math.random()*1.5;

        group.add(mesh);

        meshes[i] = mesh;
    }

    group.position.y = 30;
    scene.add(group);
    animate();
}

function onDocumentMouseMove(event) {
    var desksens = .75;

    mouseX = (event.clientX - windowHalfX) * desksens;
    mouseY = (event.clientY - windowHalfY) * desksens;

}

function onTouchMove(event) {

    event.preventDefault();

    var touches = event.touches;
    var touch = touches[0];

    var phonesens = 5.0;

    mouseX = (touch.clientX - windowHalfX) * phonesens;
    mouseY = (touch.clientY - windowHalfY) * phonesens;

}

initPostprocessing()

function initPostprocessing(){
    const renderPass = new RenderPass( scene, camera );

    const bokehPass = new BokehPass( scene, camera, {
        focus: 50,
        aperture: 0.0001,
        maxblur: .005
    } );

    composer = new EffectComposer( renderer );

    
    composer.addPass( renderPass );
    composer.addPass( bokehPass )
    


}


function animate() {
    requestAnimationFrame(animate);
    render();
}

function render() {

    camera.position.x += (0.125 * mouseX - camera.position.x) * .0125;
    camera.position.y += (-0.125 * mouseY - camera.position.y) * .0125;

    camera.lookAt(scene.position);
    //distance = camera.position.distanceTo(scene.position)

    var delta = clock.getDelta();

    for (var i = 0; i < N_OBJECTS; i++) {

        var mesh = meshes[i];

        mesh.rotation.x += 0.15 * delta;
        mesh.rotation.y += 0.25 * delta;
        particleMesh.rotation.y += 0.00001*delta
        //check if mesh lower than a certain point
        if(mesh.position.y<-100){
            mesh.position.y += 160
        }

        mesh.position.y = (mesh.position.y - .3 * delta) % 8000;

    }

    //renderer.render(scene, camera);
    composer.render(delta)
}